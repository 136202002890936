import React, { useState, useEffect } from "react"
import LayoutSubpage from "../layouts/layout_subpage"

const NotFoundPage = () => {
  const [isMount, setMount] = useState(false)

  useEffect(() => {
    setMount(true)
  }, [])

  if (!isMount) {
    return <div className="layout layout--subpage"></div>
  }

  return (
    <LayoutSubpage title="Błąd 404">
      <h1>Niestety nie udało się odnaleźć tej strony</h1>
    </LayoutSubpage>
  )
}

export default NotFoundPage
